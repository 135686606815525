import axios from 'axios'

const API = 'etiquetas'

const ENDPOINTS = {
	getEtiquetas() {
		return axios(`${API}`)
	},
	insertEtiqueta(payload) {
		return axios.post(`${API}`, payload)
	},
	deleteEtiqueta(idEtiqueta) {
		return axios.delete(`${API}/${idEtiqueta}/eliminar`)
	},
	updateEtiqueta(idEtiqueta, payload) {
		return axios.put(`${API}/${idEtiqueta}/edit`, payload)
	},
	updatePosiciones(payload) {
		return axios.put(`${API}/posiciones-update`, payload)
	},
};

export default ENDPOINTS
