<template>
    <section class="conf-products-tags h-100 overflow-auto scroll-none">
        <div class="row border-bottom f-600 pl-5 pr-3 py-3 text-general">
            <p>Etiquetas</p>
        </div>
        <div class="p-3 f-15 h-90">
            <div class="row mx-0 my-3 px-3 text-general">
                Crea los grupos de etiquetas que identificarán los productos que venden los tenderos
            </div>
            <!-- create group -->
            <ValidationObserver ref="validacionGrupo" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{ errors }" vid="nombre" rules="required|max:15" name="grupo" class="d-flex flex-column">
                            <label class="f-12 ml-2 text-general">Grupo</label>
                            <el-input v-model="groupName" maxlength="15" class="pr-input" placeholder="Nombre del grupo" size="small" show-word-limit >
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto px-0 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear etiqueta" placement="bottom">
                            <div class="btn-red cr-pointer br-8 mt-3" @click="insertEtiqueta(valid)">
                                <i class="icon-plus f-16 text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <!-- groups list -->
            <div class="row mx-0">
                <div class="col-12">
                    <el-collapse v-model="grupos" accordion>
                        <draggable :list="gruposEtiquetas" handle=".handle" @change="changeItemsPositions">
                            <el-collapse-item v-for="(item, index) in gruposEtiquetas" :key="index" :name="index" class="my-3" >
                                <template slot="title">
                                    <ValidationObserver ref="validacion_etiquetas" v-slot="{ valid }" class="w-100">
                                        <div class="d-middle w-100">
                                            <i class="icon-drag f-22 cr-pointer handle text-muted mx-2" />
                                            <ValidationProvider v-slot="{ errors }" :vid="'vp' + index" rules="required|max:15" name="etiqueta" class="d-flex flex-column">
                                                <el-input v-model="item.nombre" :disabled="editarGrupo != index" class="pr-input-45" style="width:250px;" size="small" />
                                                <span class="text-danger f-11" style="line-height: 0px;"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                            <!-- {{ item.nombre }} -->
                                            <el-tooltip v-if="editarGrupo == index" class="item" effect="light" content="Editar" placement="bottom" >
                                                <div class="btn-action border mr-auto mx-2" @click.stop="updateEtiqueta(item, valid)" >
                                                    <i class="icon-ok-circled-outline text-success f-20" />
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip v-else content="Editar" placement="bottom" effect="light" :show-arrow="false" :offset="0">
                                                <div class="btn-action border ml-auto mx-2" @click.stop="editarGrupo = index" >
                                                    <i class="icon-pencil f-20" />
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip v-if="editarGrupo != index" content="Eliminar" placement="bottom" effect="light" :show-arrow="false" :offset="0">
                                                <div class="btn-action border mr-2" @click.stop="openModalDeleteGroup(item.id)" >
                                                    <i class="icon-delete-outline f-20" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </ValidationObserver>
                                </template>
                                <div>
                                    <!-- add etiqueta -->
                                    <ValidationObserver ref="validacion_etq" v-slot="{ valid }">
                                        <div class="row mx-0 my-3">
                                            <div class="col-auto">
                                                <ValidationProvider v-slot="{ errors }" vid="nombre" rules="required|max:15" name="etiqueta" class="d-flex flex-column">
                                                    <label class="f-12 ml-2 text-general">Etiqueta</label>
                                                    <el-input v-model="model.nombre" maxlength="15" class="pr-input" placeholder="Nombre de la etiqueta" size="small" show-word-limit >
                                                        <!-- <i v-if="errors.length" slot="suffix"
															class="icon-attention-alt text-danger el-input__icon f-17" /> -->
                                                    </el-input>
                                                    <!-- <span class="text-danger w-100 f-11"> {{ errors[0] }} </span> -->
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-auto px-0 pt-1">
                                                <el-tooltip class="item" effect="light" content="Crear etiqueta" placement="bottom" >
                                                    <div class="btn-red cr-pointer br-8 mt-3" @click="insertEtiqueta(valid, item.id)" >
                                                        <i class="icon-plus f-16 text-white" />
                                                    </div>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    <p class="mx-3 my-2 mt-5 text-general">Lista de etiquetas</p>
                                    <div v-if="item.etiquetas_hijas.length" class="overflow-auto custom-scroll" style="height:calc(100% - 150px);" >
                                        <ValidationObserver ref="validacion_etq" v-slot="{ valid }">
                                            <div class="row mx-0">
                                                <div v-for="(etiqueta, idx) in item.etiquetas_hijas" :key="idx" class="col-8 px-1" >
                                                    <div class="row mx-0 my-2 align-items-center">
                                                        <div class="col-auto px-2">
                                                            <ValidationProvider v-slot="{ errors }" :vid="'vp' + idx" rules="required|max:15" name="etiqueta" class="d-flex flex-column">
                                                                <el-input v-model="etiqueta.nombre" :disabled="editar != idx" class="pr-input-45" style="width:180px;" maxlength="15" show-word-limit />
                                                                <span class="text-danger w-100 f-11"> {{ errors[0] }}
                                                                </span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-auto px-0">
                                                            <el-tooltip v-if="editar != idx" class="item" effect="light" content="Editar" placement="bottom" >
                                                                <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;" >
                                                                    <i class="icon-pencil-outline f-20" @click="editar = idx" />
                                                                </div>
                                                            </el-tooltip>
                                                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom" >
                                                                <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="updateEtiqueta(etiqueta, valid)" >
                                                                    <i class="icon-ok-circled-outline text-white f-20" />
                                                                </div>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="col-auto px-0">
                                                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom" >
                                                                <div class="d-middle-center cr-pointer br-10" :class="editar != idx ? '' : 'invisible'" style="width:44px;height:44px;" @click="openModalEliminarEtiqueta(etiqueta.id, item.id)" >
                                                                    <i class="icon-trash-empty f-20 hover-inverse" />
                                                                </div>
                                                            </el-tooltip>
                                                        </div>
                                                        <el-tooltip class="item" effect="light" placement="bottom">
                                                            <div slot="content" class="text-center" style="max-width:135px;" >
                                                                Productos que utilizan esta etiqueta
                                                            </div>
                                                            <div class="br-10 text-white px-2 f-17 ml-2 bg-general3">
                                                                <i class="icon-package-variant-closed f-18 mr-2" />
                                                                <span>{{ etiqueta.cantidad_productos }}</span>
                                                            </div>
                                                        </el-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationObserver>
                                    </div>
                                    <sinDatos v-if="!item.etiquetas_hijas.length" icon="list" mensaje="No se ha creado ninguna etiqueta" />
                                </div>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </div>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar etiqueta" mensaje="¿Desea eliminar la etiqueta? Esta acción no puede ser revertida." @eliminar="deleteEtiqueta(1)" />
        <modalEliminar ref="modalEliminarGrupo" titulo="Eliminar grupo" mensaje="¿Desea eliminar este grupo de etiquetas? Esta acción no puede ser revertida." @eliminar="deleteEtiqueta(2)" />
    </section>
</template>

<script>
import Etiquetas from "~/services/configurar/etiquetas";

export default {
    data(){
        return {
            model: {
                nombre: '',
            },
            editar: -1,
            etiquetas: [],
            gruposEtiquetas: [],
            editarGrupo: -1,
            groupName: '',
            activeName: null,
            grupos: null,
            idEtiqueta: null,
            idGrupo: null
        }
    },
    mounted(){
        this.getEtiquetas()
    },
    methods: {
        openModalDeleteGroup(id){
            this.idEtiqueta = id
            this.$refs.modalEliminarGrupo.toggle()
        },
        openModalEliminarEtiqueta(id, idGrupo){
            this.idEtiqueta = id
            this.idGrupo = idGrupo
            this.$refs.modalEliminar.toggle()
        },
        pushEtiqueta(item){
            const idx = this.gruposEtiquetas.findIndex((e) => e.id == item.id_padre)
            this.gruposEtiquetas[idx].etiquetas_hijas.push(item)
        },
        pushGrupo(item){
            item.etiquetas_hijas = []
            this.gruposEtiquetas.push(item);
        },
        deleteEtiquetaHija(){
            const idx = this.gruposEtiquetas.findIndex((e) => e.id == this.idGrupo);
            const etiqueta = this.gruposEtiquetas[idx].etiquetas_hijas.findIndex((e) => e.id == this.idEtiqueta)
            this.gruposEtiquetas[idx].etiquetas_hijas.splice(etiqueta, 1)
        },
        deleteGrupo(){
            const idx = this.gruposEtiquetas.findIndex((e) => e.id == this.idEtiqueta);
            this.gruposEtiquetas.splice(idx, 1)
        },
        async getEtiquetas(){
            try {
                const { data } = await Etiquetas.getEtiquetas();
                this.gruposEtiquetas = data.data;
            } catch (e){
                this.error_catch(e);
            }
        },
        async updateEtiqueta(item, valid){
            try {
                if (!valid) return this.notificacion('Warning', "Por favor complete todos los datos", "warning")
                const payload = {
                    nombre: item.nombre
                }
                const { data } = await Etiquetas.updateEtiqueta(item.id, payload)
                this.editar = -1
                this.editarGrupo = -1
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
        async insertEtiqueta(valid, item = null){
            try {
                if (!valid) return this.notificacion('Warning', "Por favor complete todos los datos", "warning")
                let payload = {
                    idPadre: null
                }
                if (item != null){
                    payload.idPadre = item;
                    payload.nombre = this.model.nombre;
                } else {
                    payload.nombre = this.groupName;
                };
                const { data } = await Etiquetas.insertEtiqueta(payload)
                this.notificacion('', data.mensaje, 'success')
                this.model.nombre = '';
                this.groupName = '';
                this.$refs.validacionGrupo.reset()
                if(this.$refs.validacion_etq){
                    // this.$refs.validacion_etq.reset();
                }
                if (item != null){
                    this.pushEtiqueta(data.data);
                } else {
                    this.pushGrupo(data.data);
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async deleteEtiqueta(tipo){
            try {
                const { data } = await Etiquetas.deleteEtiqueta(this.idEtiqueta)
                this.notificacion('', data.mensaje, 'success')
                if (tipo == 1){
                    this.$refs.modalEliminar.toggle()
                    this.deleteEtiquetaHija()
                } else {
                    this.deleteGrupo()
                    this.$refs.modalEliminarGrupo.toggle()
                }
                this.idEtiqueta = null;
                this.idGrupo = null;
            } catch (e){
                this.error_catch(e)
            }
        },
        async changeItemsPositions(){
            try {
                const payload = {
                    idsItems: _.map(this.gruposEtiquetas, 'id'),
                };
                const { data } = await Etiquetas.updatePosiciones(payload)
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error);
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.conf-products-tags {
	background-color: white;
}
</style>
